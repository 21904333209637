html {
  height: 100vh;
}
#root {
  min-height: 100vh !important;
  height: 100vh;
  background-color: rgb(76, 26, 99);
  box-shadow: 6px 0px 6px #000000 !important;
  /* background: #24062c 0% 0% no-repeat padding-box !important; */
  /* background: linear-gradient(-45deg, #1e0e31, #131124, #4d1f51); */
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-Container {
  margin-top: 5.6%;
  overflow-y: auto;
  background: linear-gradient(-45deg, #1e0e31, #131124, #4d1f51);
}
.scrollbar-Wrapper::-webkit-scrollbar {
  width: 8px;
}
/* .scrollbar-Wrapper::-webkit-scrollbar-track {
  background-color: #180f2a;
} */
.scrollbar-Wrapper:hover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 1);
  background-color: #fff;
  border-radius: 5px;
}

.logo-container {
  width: 12rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative;
  top: 4px;
  cursor: pointer; */
}

.simulation-container {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.simulation-image {
  height: 180px;
  width: inherit;
  border-radius: 30px;
  object-fit: contain;
  transition-duration: "1s";
}
.simulation-title {
  padding: 10px;
  width: min-content;
  margin: auto !important;
}

/* .scrollbar-Wrapper:hover::-webkit-scrollbar {
  display: inline;
  width: 10px;
} */
/* .scrollbar-Wrapper:hover:-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  background-color: #fff;
  border-radius: 0px;
  display: inline;
} */

/* .scrollbar-Wrapper::-webkit-scrollbar {
  width: 8px;
}
.scrollbar-Wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar-Wrapper::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  background-color: #663267;
  border-radius: 5px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form_control {
  position: relative;
}

.field-error {
  color: #f00;
  font-size: 12px;
}
.errorText {
  color: #f00;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 40px;
  padding: 5px;
}

.button-stack {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 350px;
  z-index: 999;
}
.button-stack.three-buttons {
  width: 480px;
}
.button-stack.form-btn-wrapper {
  text-align: center;
  margin: 60px 54px;
  left: 0;
}

.form-wrapper .text-field {
  width: 90%;
}
.form-wrapper .select-field {
  width: 90%;
}

button.button-alert {
  width: auto;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.asset-title {
  text-transform: capitalize;
}
.folder {
  width: 6.25rem;
}

/* Hide the React Flow Attribution element from DOM */
.react-flow__attribution {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
