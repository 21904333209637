.SystemModalContainer .MuiDialog-paper {
  border: 1px solid #2a1735;
  height: 100vh;
}
.SystemModalContainer .modal-title {
  position: relative;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .SystemModalContainer .icon-close {
  color: #fff;
} */
 
.folder-wrapper {
  margin-right: 1.25rem;
}
.folder {
  width: 6.25rem;
  cursor: pointer;
}
.folder-name {
  color: #fff;
  font-size: '0.875rem !important';
  padding-top: 5px;
}