.dropZone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  overflow: hidden;
  padding: 20px;
 /* border: 2px dashed #fff; */
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.image-container {
  border: 2px dashed gainsboro;
  color: #fff;
  box-sizing: border-box;
  height: 210px;
  flex: 1;
  position: relative;
}
 
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 10px;
  opacity: 1;
  /* transition: 0.5s ease; */
  backface-visibility: hidden;
}

.overlay {
  /* transition: 0.5s ease; */
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right; 
  width: 100%;
}

.image-container:hover .image {
  opacity: 0.3;
}

.image-container:hover .overlay {
  opacity: 1;
}

.placeholder-container {
  width: 100%;
  max-width: 500px;
}