.ModalContainer {
  width: 40%;
  left: unset !important;
}
.ModalContainer.max-width {
  width: 70%;
}
/* .ModalContainer .MuiDialog-paper {
  background-color: #000000D9 !important;
  background-image: none;
} */ 
/* .ModalContainer .modal-title {
  color: #fff;
  position: relative;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
/* .ModalContainer .icon-close {
  color: #fff;
} */
.form-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  width: 400px;
}
 