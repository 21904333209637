/* .select-label {
  color: #fff !important;
} */ 
.previewImg {
  width: inherit !important;
}
.imgContainer {
  width: calc(100% - 13%);
}
.simulation-card {
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin: 32px 0 0 32px !important;
  max-width: 22.6% !important;
}

.banner-image {
  width: 100% !important;
  height: 150px !important;
  object-fit: contain !important;
}
