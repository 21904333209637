.MuiTablePagination-selectLabel {
  margin-bottom: 0;
  margin-top: 0;
}
.container-Text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.date-Text {
  margin-right: 2rem;
}
.formSpace {
  margin-bottom: 4rem;
}
.tableHeader {
  font-weight: bold !important;
  font-size: 1rem !important;
}
.MediaCard {
  background: #673068 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.title {
  color: #fff;
  margin-top: 10px !important;
}
.addContainer {
  width: 300px;
}
