.MediaCard {
  background: #673068 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}
.MediaCardContent {
  color: inherit !important;
  text-transform: capitalize;
}
.MediaImg {
  /* object-fit: contain !important; */
}
.MediaCard-title{
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
