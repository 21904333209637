.SimulationBuilder {
}

.node-inline-naming {
  color: #fff;
  font-size: 12px;
  line-height: 2.2;
  margin-left: 24px;
  margin-bottom: -1.5;
}

.node-list-title {
  padding-top: 13px;
  margin-left: 20px !important;
  font-size: 16px !important;
}

.title-section {
  margin-bottom: 20px;
  width: inherit
}
.title-section h5 {
  letter-spacing: 0px;
}
.workspace-section {
  padding: 20px;
  height: 96.5vh;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}
.flow-wrapper {
  flex-grow: 1;
  height: 100%;
  position: relative;
}
.nodes {
  padding: 20px 0;
  gap: 15px;
  cursor: grab;
  text-align: center;
}
.node {
  height: 89px;
  width: 170px;
  background-color: #663267;
  border-radius: 4px 12px;
  position: relative;
}
.node span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
}
.node .node-name {
  cursor: url(Assets/context-menu.png), auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #663267;
  width: 115px;
  top: 20%;
  margin-left: 25px;
}
.node.is-published .node-name {
    cursor: grab;
  }
.node span.track {
  cursor: pointer;
}
.node textarea {
  color: #fff;
  border: 0;
  appearance: none;
  background-color: transparent;
  outline: none;
  position: relative;
  top: 16px;
  padding: 10px;
}
.node .close-button {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  z-index: 99999;
}
.node .close-button:hover {
  background-color: #fff;
}
.node:hover .close-button {
  display: inline-flex;
}
.node .close-button .icon-close {
  color: #2a1735;
  font-size: 1rem;
}

.in-active .node .triangle {
  color: #727375;
}

.form-wrapper .form-btn-wrapper {
  bottom: 0;
  margin: 0 45px;
  width: auto;
  padding: 20px 0;
  background-color: #000000d9;
}
.form-wrapper .form-btn-wrapper button {
  width: 18%;
}
.form-wrapper .container {
  margin-bottom: 100px;
}
.questions-form .form_control {
  width: 90%;
  margin-top: 20px;
}

.field-array-title-wrapper {
  position: relative;
}
.field-array-title {
  /* color: #fff; */
  margin-top: 40px !important;
}
.card-wrapper {
  width: 100% !important;
}
/* .card-wrapper label.MuiInputLabel-root {
  color: #2a1735;
  border-color: #2a1735;
}
.card-wrapper label.Mui-focused {
  color: #2a1735 !important;
}
.card-wrapper .text-field input {
  color: #2a1735;
}
.card-wrapper .text-field fieldset {
  border-color: #2a1735 !important;
  color: #2a1735 !important;
} */

.menus-form-wrapper .card-wrapper {
  width: 100% !important;
}

.menu-items-wrapper .field-array-title {
  /* color: #2a1735; */
  margin-top: 20px !important;
}
.menu-items-container {
  position: relative;
  border: 1px solid #673068;
  border-radius: 6px;
  padding: 6px 20px 20px;
  margin: 20px 0 10px !important;
}

/* .filled-inputs label.MuiInputLabel-root {
  color: #fff;
  border-color: #fff;
} */
/* .filled-inputs label.Mui-focused {
  color: #fff !important;
} */
/* .filled-inputs .text-field input {
  color: #fff;
  border-bottom: 1px solid #fff;
} */
.filled-inputs .text-field fieldset {
  /* border-color: #fff !important; */
  color: #fff !important;
}
.filled-inputs .MuiFormControl-root {
  margin-top: 0;
}
.filled-inputs .form_control label.MuiInputLabel-root {
  top: 4px;
  left: -2px;
}
.filled-inputs .form_control label.Mui-focused {
  top: 12px;
  left: -2px;
}
.filled-inputs .form_control label.MuiInputLabel-shrink {
  top: 12px;
  left: -2px;
} 
.filled-inputs .form_control .MuiFilledInput-root::before {
  border-bottom-color: rgba(255, 255, 255, 0.6);
}
/* .filled-inputs .form_control .MuiSelect-select {
  color: #fff;
} */ 
/* .filled-inputs .form_control .MuiSvgIcon-root {
  color: #fff;
} */
.filled-inputs .form_control.type-select label.MuiInputLabel-shrink {
  top: 0;
}
/* .filled-inputs .form_control.type-select fieldset {
  border-color: #fff;
} */

.head-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  margin-right: 10px !important;
}

.table-container.MuiPaper-root {
  background-color: transparent;
  border: 1px solid #673068;
  padding: 5px 15px;
}
.table-container .table-head th {
  font-weight: 700;
  /* color: #fff; */
}
/* .table-container .table-body td {
  color: #fff;
} */
.table-container .table-body tr:last-child td {
  border-bottom: 0;
}
.table-container .btn-wrapper {
  text-align: center;
  padding-left: 30px !important;
  padding-top: 20px !important;
}

.icon-file-grid .MuiInputBase-root {
  width: 324px;
}
.icon-file-grid .MuiIconButton-root {
  left: 10px;
}

.rightEdge {
  width: 20px !important;
  height: 20px !important;
  right: -8px !important;
}
.leftEdge {
  width: 20px !important;
  height: 20px !important;
  left: -8px !important;
}
path.react-flow__edge-path {
  stroke-width: 4;
  cursor: pointer;
}
